<template>
  <div>
    <div class="upload_list">
      <div class="uploading">
        <div class="context_upload">
          <el-row id="head">
            <el-col :span="6">
              <span>素 材</span>
            </el-col>
            <el-col :span="3">
              <span>格 式</span>
            </el-col>
            <el-col :span="3">
              <span>大小</span>
            </el-col>
            <el-col :span="4">
              <span>上传进度</span>
            </el-col>
            <el-col :span="4">
              <span>上传时间</span>
            </el-col>
            <el-col :span="4">
              <span>操 作</span>
            </el-col>
            <el-divider></el-divider>
          </el-row>
          <div class="listcontain" ref="listcontain">
            <div class="itemContain" v-for="(item,i) in uploadList" :key="i">
              <el-row id="item">
                <el-col :span="6">
                  <el-tooltip effect="dark" :content="item.name" placement="bottom" popper-class="uploading_tooltip">
                    <span class="metirial_name one_line_ellipsis">{{item.name}}</span>
                  </el-tooltip>
                </el-col>
                <el-col :span="3">
                  <span>{{item.format}}</span>
                </el-col>
                <el-col :span="3">
                  <p>{{item.total_size | transformByte}}</p>
                  <!-- <p style="padding-top: 10px">{{item.resolution}}</p>
                  <p style="padding-top: 10px" v-if="item.type == 1">{{item.duration}}</p> -->
                </el-col>
                <el-col :span="4" v-if="item.finish_if == 1">
                   <span>上传完成</span>
                </el-col>
                <el-col :span="4" v-if="item.finish_if == -1">
                   <span>已取消</span>
                </el-col>
                <el-col :span="4">
                  {{item.insert_time | formatTime}}
                </el-col>
                <el-col :span="4">
                  <div v-if="item.finish_if == 1 || item.finish_if == -1">
                  <el-tooltip effect="dark" content="删除该记录" placement="top">
                    <i
                      @click="deleHistory(item.id)"
                      style="cursor: pointer"
                      class="iconfont icon-shanchujilu"
                    ></i>
                  </el-tooltip>
                  </div>
                </el-col>
              </el-row>
              <el-divider></el-divider>
            </div>
            <noResult v-if="uploadList.length == 0 && judgeBottom == 0" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      uploadList: [],
      pageCount: 1, //页码
      totalCount: 0, //数据总条数
      limitCount: 40, //每页条数
      judgeBottom: 2, //底部显示, 0 暂无数据 1 正在加载 2 上滑刷新
    };
  },
  computed: {
    uploadingPage:{
      get() {
        return this.$store.state.uploading
      },
      set(val){
          // this.$store.commit('uploadingPage', val)
      }
    }
  },
  watch: {
    uploadingPage(val) {
      if(val == true){
        this.reload();
      }
    },
  },

  created () {
    this.$store.commit('uploadingPage', false)
    this.getUploadList('finish') //上传列表
  },
  mounted () {
    //翻页
    this.$nextTick(()=>{
      let scrollContent = this.$refs.listcontain
      scrollContent.onscroll = this.throttle(() => {
        this.scrollBottom(this.pageCount, this.limitCount, this.totalCount)
      }, 2000) //滑动则触发事件,2000毫秒响应一次
    })

  },
  methods: {
     // 删除历史
    deleHistory (id) {
      const formData = new FormData()
      formData.append("id",id)
      this.$http({
        method: 'POST',
        url: '/upload/delete_task/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.getUploadList()
        } else {

        }
      }).catch(error => console.log(error))
    },
       // 上传列表
   getUploadList (flag) {
       this.pageCount = 1
       this.totalCount = 0
       this.$nextTick(()=>{
        this.$refs.listcontain.scrollTop = 0
       })
       this.$store.commit('uploadingPage', false)
       return this.$http({
          method: 'GET',
          url: '/upload/get_upload_list/',
          params: {
            page: this.pageCount,  //页码，从1,开始
            page_num: this.limitCount,  //每页数量、
            flag: flag == 'finish' ? 1:0
          }
        }).then(res => {
          if (res.data.status === 0) {
            this.uploadList = res.data.result;

            this.pageCount += 1
            this.totalCount = res.data.total_num //总条数
            if (res.data.result.length < this.limitCount) {
                //如果第一次请求的数据数组的长度没小于每次规定返回的数据条数,表示后端没有多余的值
              this.judgeBottom = 0  //底部显示暂无数据
            }
          } else {
              this.$message.error(res.data.msg);
          }
        }).catch(error => console.log(error))
    },
   scrollBottom(pageC, limitC, totalCount) {
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 100   //距离底部200px时则开始触发函数
      let loadStat = 1 //赋值,表示显示正在加载
      this.judgeBottom = loadStat //显示正在加载
     if (
        bottomOfWindow && loadStat == 1
        (pageC - 1) * limitC < totalCount
        //如果此时到了滑动底部&&底部显示正在加载&&请求过的总数据小于后台总数据
      ) {
        this.pageCount += 1 //页码加一,为了保证取到下次数据
        this.$http({
        method: 'GET',
        url: '/upload/get_upload_list/',
        params: {
          page: pageC,  //页码，从1,开始
          page_num: limitC,  //每页数量
        }
      }).then(res => {
          let temp = this.uploadList
          let len = res.data.result.length
          if (len === 0) {
            loadStat = 0 //显示 暂无数据
          }else if (res.data.status === 0 && temp !== undefined) {
            let arrT = res.data.result
            temp.push.apply(temp, arrT)
            loadStat = 2   //表示 向上刷新
        } else {
          loadStat = 0   //表示 暂无数据
        }
      }).catch(error => console.log(error))
      }else if ((pageC - 1) * limitC >= totalCount) {
        // (页码数-1)*每次请求返回数 >= 总条数
        //代表拿到数据库中的所有数据了，
        //之所以-1是为了防止存在剩下的数据条数不足一个limitC,所以会导致不发送请求
        loadStat = 0
      } else {
        loadStat = 2
      }
      this.judgeBottom = loadStat //修改loading显示
   },
    //公用定时器 , 用于函数节流，防止多次触发监听
   throttle(fn, delay) {
      let timer = null
      let firstTime = true
      return function(...args) {
        if (firstTime) {
          // 第一次加载
          fn.apply(this, args)
          return (firstTime = false)
        }
        if (timer) {
          // 定时器正在执行中，跳过
          return
        }
        timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          fn.apply(this, args)
        }, delay)
      }
   },

  },
  beforeDestroy() {
    //销毁监听事件,由于是单页面应用,不销毁的话监听事件会一直存在
    let scrollContent = this.$refs.listcontain
    scrollContent.removeEventListener('scroll', this.scrollBottom,true)
  },

};
</script>

<style lang="scss" scoped>
  .uploading {
  // 内容
  .context_upload {
    padding: 40px 3.5vw 0 3.2vw;
    // 列表头部
    #head {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
      // 分割线
      .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 30px 0px 0px 0px;
        background: #3e3d4c;
      }
    }
    // 全部列表容器
    .listcontain {
      overflow-y: auto;
      height: 80vh;
      // 每一项容器
      .itemContain {
        // 列表项
        #item {
          height: 116px;
          display: flex;
          align-items: center;
          text-align: center;
          .metirial_name {
            display: block;
            // 多行文本超出显示...
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 4;
            // overflow: hidden; //盒子溢出隐藏
            // word-break: break-all;
          }
        }
        // 分割线
        .el-divider--horizontal {
          display: block;
          height: 1px;
          width: 100%;
          margin: 0px 0px 0px 0px;
          background: #3e3d4c;
        }
      }
    }
    // 全部列表的滚动条
    .listcontain::-webkit-scrollbar {
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    .listcontain::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 7px;

      background: #b9bac9;
    }
    .listcontain::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 7px;
      background: #5e5d6e;
    }
  }
  }
</style>
<style lang="scss">
.uploading_tooltip.el-tooltip__popper{
  max-width: 18%;
  line-height: 20px;
}
</style>
