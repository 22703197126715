var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "upload_list" }, [
      _c("div", { staticClass: "uploading" }, [
        _c(
          "div",
          { staticClass: "context_upload" },
          [
            _c(
              "el-row",
              { attrs: { id: "head" } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", [_vm._v("素 材")])
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", [_vm._v("格 式")])
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", [_vm._v("大小")])
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c("span", [_vm._v("上传进度")])
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c("span", [_vm._v("上传时间")])
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c("span", [_vm._v("操 作")])
                ]),
                _c("el-divider")
              ],
              1
            ),
            _c(
              "div",
              { ref: "listcontain", staticClass: "listcontain" },
              [
                _vm._l(_vm.uploadList, function(item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "itemContain" },
                    [
                      _c(
                        "el-row",
                        { attrs: { id: "item" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: item.name,
                                    placement: "bottom",
                                    "popper-class": "uploading_tooltip"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "metirial_name one_line_ellipsis"
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v(_vm._s(item.format))])
                          ]),
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm._f("transformByte")(item.total_size))
                              )
                            ])
                          ]),
                          item.finish_if == 1
                            ? _c("el-col", { attrs: { span: 4 } }, [
                                _c("span", [_vm._v("上传完成")])
                              ])
                            : _vm._e(),
                          item.finish_if == -1
                            ? _c("el-col", { attrs: { span: 4 } }, [
                                _c("span", [_vm._v("已取消")])
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatTime")(item.insert_time)) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 4 } }, [
                            item.finish_if == 1 || item.finish_if == -1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: "删除该记录",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-shanchujilu",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleHistory(item.id)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c("el-divider")
                    ],
                    1
                  )
                }),
                _vm.uploadList.length == 0 && _vm.judgeBottom == 0
                  ? _c("noResult", {
                      attrs: {
                        iconUrl: "icon-meiyousucai",
                        iconSty: "font-size:80px",
                        textSty: "font-size:18px",
                        noResultText: "暂无数据"
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }